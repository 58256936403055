import { useState } from "react";
import { getStorePurchase } from "../store/slices/storePurchaseSlice";
import { useDispatch } from "react-redux";
import { getorderDetails } from "../store/slices/orderDetailsSlice";
import { useNavigate } from "react-router-dom";

export const useStorePurchase = () => {
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState({});
  const [responseError, setResponseError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [select, setSelect] = useState("1");

  const formSubmitSender = async (data) => {
    setLoader(true);
    const payload = {
      gift_card_id: data.giftCard,
      amount: data.amount,
      name: data.senderName,
      email: data.senderEmail,
      message: "",
      type: data.type,
      date: data.date,
      mobile: data.senderMobile,
      purchase_mode: select === "1" ? 1 : 2,
      languageType: localStorage.getItem("language_type"),
      token: localStorage.getItem("authToken"),
    };
    const resultAction = await dispatch(getStorePurchase(payload));
    if (resultAction?.payload?.code == 500) {
      setResponseError(resultAction?.payload?.message);
      setLoader(false);
      setTimeout(() => {
        setResponseError(false);
      }, 3500);
    }

    if (getStorePurchase.fulfilled.match(resultAction)) {
      const purchase_id = resultAction?.payload?.data?.data?.id;

      if (resultAction?.payload?.data?.success == true) {
        const lang = localStorage.getItem("language_type");
        navigate(`/checkout/${purchase_id}/${lang}`);
        setLoader(false);
      }
    }
  };

  const formSubmitRecipient = async (data) => {
    setLoader(true);
    const payload = {
      gift_card_id: data.giftCard,
      amount: data.amount,
      name: data.recpName,
      sender_name: data.senderRecpName,
      email: data.recpEmail,
      message: data.message,
      type: data.type,
      date: data.date,
      mobile: data.recpmobile,
      purchase_mode: select === "1" ? 1 : 2,
      languageType: localStorage.getItem("language_type"),
      token: localStorage.getItem("authToken"),
    };

    const resultAction = await dispatch(getStorePurchase(payload));

    if (resultAction?.payload?.code == 500) {
      setResponseError(resultAction?.payload?.message);
      setLoader(false);
      setTimeout(() => {
        setResponseError(false);
      }, 3500);
    }

    if (getStorePurchase.fulfilled.match(resultAction)) {
      const purchase_id = resultAction?.payload?.data?.data?.id;

      if (resultAction?.payload?.data?.success == true) {
        const lang = localStorage.getItem("language_type");
        navigate(`/checkout/${purchase_id}/${lang}`);
        setLoader(false);
      }
    }
  };

  const fetchCardDetails = (id) => {
    dispatch(getorderDetails(id))
      .unwrap()
      .then((value) => {
        setDetails(value?.data?.data);
      });
  };

  return {
    formSubmitSender,
    formSubmitRecipient,
    fetchCardDetails,
    details,
    loader,
    setLoader,
    select,
    setSelect,
    responseError,
  };
};
