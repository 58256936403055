import React from 'react';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Callabck from '../../components/Callback';

const CallabckPage = () => {
  return (
        <Callabck />
  )
}

export default CallabckPage