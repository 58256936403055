import Style from "./Callback.module.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postPaymentStatus } from "../../store/slices/paymentStatusSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Callback = () => {
  let para = useSearchParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (para) {
      setTimeout(() => {
        selectPay(para[0].get("merchant_order_id"));
      }, 2000);
    }
  }, [para]);

  const { t } = useTranslation(["common"]);
  // const { lang: langSlug } = useParams();

  const lang = localStorage.getItem("language_type");
  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!lang) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const selectPay = async (id) => {
    const resultAction = await dispatch(postPaymentStatus(id));

    if (resultAction?.payload?.data?.code == 200) {
      let langType = resultAction?.payload?.data?.data?.languageType;
      navigate(`/order-summary/${para[0].get("merchant_order_id")}/${langType}`);
    }
  };

  return (
    <div className={Style.spinner_wrap}>
      <div className="container">
        <p className={Style.page_content}>{t("Dont_close")}</p>
        <div className={Style.spinner}></div>
      </div>
    </div>
  );
};

export default Callback;
