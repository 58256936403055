import React from 'react';
import Checkout from '../../components/Checkout';
import CommonLayout from '../../components/Layouts/CommonLayout';

const CheckoutPage = () => {
  return (
        <Checkout />
  )
}

export default CheckoutPage