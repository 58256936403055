import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  paymentIframe: "",
};

export const postSelectPayment = createAsyncThunk(
  "postSelectPayment/selectPayment",
  async (params, { rejectWithValue }) => {
    let data = {
      token: localStorage.getItem("authToken"),
    };

    const api = await getAxiosInstance();
    try {
      const response = await api.post(`select-payment`, params, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const selectPaymentSlice = createSlice({
  name: "selectPayment",
  initialState,
  reducers: {},
  extraReducers: {
    [postSelectPayment.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [postSelectPayment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.paymentIframe = action.payload?.data;
    },
    [postSelectPayment.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default selectPaymentSlice.reducer;
