import React, { useEffect } from "react";
import Style from "./CheckoutPayment.module.scss";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckoutPayment = ({
  data,
  setWallet,
  setIFrame,
  walletError,
  paymentError,
  selectPay,
}) => {
  const { lang: langSlug } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  return (
    <div className={Style.payment_detail}>
      <div className={`h5 ${Style.payment_detail_title}`}>
        {t("Select_payment_method")}
      </div>

      <div className={`row ${Style.custom_row}`}>
        <Form.Check
          type="radio"
          name="type"
          id="card"
          value="1"
          label={t("cardPayments")}
          onClick={(e) => {
            selectPay(e.target.value);
            setWallet(false);
          }}
          defaultChecked="1"
          defaultValue={"1"}
        />
{/* 
        <Form.Check
          type="radio"
          name="type"
          id="valu"
          value="3"
          label={"ValU"}
          onClick={(e) => {
            selectPay(e.target.value);
            setWallet(false);
          }}
        />

        <Form.Check
          type="radio"
          name="type"
          id="sympl"
          value="4"
          label={t("Sympl")}
          onClick={(e) => {
            selectPay(e.target.value);
            setWallet(false);
          }}
        />

        <Form.Check
          type="radio"
          name="type"
          id="mobile"
          value="2"
          label={t("mobileWallet")}
          onClick={(e) => {
            setWallet(true);
            setIFrame(false);
          }}
        /> */}

        <div
          className={
            walletError
              ? `error-field  ${Style.wallet_field}`
              : Style.wallet_field
          }
        ></div>

        <div className="col-12">
          <div className={Style.amount_summary}>
            {t("Total_amount")} :
            <span>
              {" "}
              <sup>{t("EGP")}</sup>
              {parseInt(data.amount)}{" "}
            </span>
          </div>
        </div>

        <small className={Style.error}>
          {paymentError && <p>{paymentError}</p>}
        </small>
      </div>
    </div>
  );
};

export default CheckoutPayment;
