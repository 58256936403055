import { useState } from "react";

const usePaymentMethod = () => {
  const [iFrame, setIFrame] = useState(null);
  const [iFrameModal, setIFrameModal] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [walletNumber, setWalletNumber] = useState("");
  const [walletValue, setWalletValue] = useState("");
  const [walletError, setWalletError] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  return {
    iFrame,
    setIFrame,
    iFrameModal,
    setIFrameModal,
    wallet,
    setWallet,
    walletNumber,
    setWalletNumber,
    walletValue,
    setWalletValue,
    walletError,
    setWalletError,
    paymentError,
    setPaymentError,
  };
};

export default usePaymentMethod;
