import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Style from "./Checkout.module.scss";
import CheckoutFor from "./CheckoutFor";
import OrderSummary from "./OrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import VatAlert from "./VatAlert";

import { postSelectPayment } from "../../store/slices/selectPaymentSlice";
import { getCancelOrder } from "../../store/slices/orderDetailsSlice";
import usePaymentMethod from "../../logic/usePaymentMethod";
import { useStorePurchase } from "../../logic/useStorePurchase";

const Checkout = () => {
  const radio = "1";

  useLayoutEffect(() => {
    setTimeout(() => {
      selectPay("1");
    }, 500);
  }, []);

  const { t } = useTranslation(["common"]);

  const textRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug, lang: langSlug } = useParams();
  const { fetchCardDetails, details } = useStorePurchase();

  const { iFrame, setIFrame, iFrameModal, setIFrameModal, wallet, setWallet, walletNumber, setWalletNumber, walletValue, setWalletValue, walletError, setWalletError, paymentError, setPaymentError } = usePaymentMethod();

  useEffect(() => {
    fetchCardDetails(slug);
  }, []);

  const { status } = useSelector((state) => state.selectPayment);

  const lang = localStorage.getItem("language_type");
  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const cancelButton = async () => {
    const resultAction = await dispatch(getCancelOrder(slug));

    if (getCancelOrder.fulfilled.match(resultAction)) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  };

  const selectPay = async (value) => {
    if (value == "2" && walletNumber.length < 10) {
      setWalletError(true);
    } else {
      setWalletError(false);
    }
    const payload = {
      order_id: localStorage.getItem("orderID"),
      languageType: localStorage.getItem("language_type"),
      token: localStorage.getItem("authToken"),
      payment_type: value,
      wallet_number: walletNumber ? walletNumber : "",
    };

    const resultAction = await dispatch(postSelectPayment(payload));

    if (resultAction?.payload?.code == 200) {
      if (resultAction?.payload?.data?.type == "2") {
        window.location.href = resultAction?.payload?.data?.url;
      } else {
        setIFrame(resultAction?.payload?.data?.url);
        // if (textRef.current) {
        //   window.scrollTo({
        //     top: textRef.current.offsetTop,
        //     behavior: "smooth",
        //   });
        // }
      }
      setPaymentError(resultAction?.payload?.errors);
    }
  };

  return (
    <section className={Style.checkout_page}>
      <div className="container">
        <div className="sm_container">
          <div className="text-start">
            <h1 className="h5">{t("CHECKOUT")}</h1>
          </div>
          <div className={`row ${Style.custom_row}`}>
            <div className="col-md-6">
              <CheckoutFor data={details} />
            </div>
            <div className="col-md-6">
              <OrderSummary data={details} />
              <VatAlert />
              <CheckoutPayment data={details} iFrameModal={iFrameModal} setIFrameModal={setIFrameModal} iFrame={iFrame} setIFrame={setIFrame} setWallet={setWallet} walletError={walletError} paymentError={paymentError} selectPay={selectPay} />
            </div>
            <div className="col-12" ref={textRef}>
              <div className={`${Style.card_payment_gateway} mt-4`}>{iFrame && <iframe src={iFrame} className={Style.iframe_srcz} title="payment gateway" />}</div>

              <div className={`${Style.card_payment_gateway} ${Style.wallet_form} mt-4 gap-3 row justify-content-between align-items-center`}>
                {wallet && (
                  <>
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        placeholder={t("walletNumber")}
                        className={`col-12`}
                        onChange={(e) => {
                          setWalletNumber(e.target.value);
                          setWalletValue(e.target.value);
                          if (walletNumber.length >= 9) {
                            setWalletError(false);
                          }
                        }}
                        maxLength={11}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={walletValue}
                      />

                      {walletError && <span className="formikError walletError">{t("min10")}</span>}
                    </div>
                    <div className={`${Style.amount_summary} col-12 col-md-4`}>
                      {t("Total_amount")} :
                      <span>
                        <sup>{t("EGP")}</sup>
                        <b>{parseInt(details?.amount)}</b>
                      </span>
                    </div>
                    <div className="col-12 col-md-7">
                      <Button
                        disabled={walletError ? true : false}
                        variant="primary"
                        type="button"
                        className={status == "loading" ? ` ${Style.payment_btn} loading col-5` : `${Style.payment_btn} col-5`}
                        id="payButton"
                        onClick={() => {
                          selectPay("2");
                        }}
                      >
                        {t("PAY")}
                      </Button>
                      <Button variant="tertiary" type="button" className={`${Style.payment_btn} col-5 ${lang == "en" ? "ms-5" : "me-5"}`} onClick={cancelButton}>
                        {t("CANCEL")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
