import React from 'react';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Transaction from '../../components/Transaction';

const TransactionPage = () => {
  return (
        <Transaction />
  )
}

export default TransactionPage