import { combineReducers } from "@reduxjs/toolkit";
import giftCardSlice from "./slices/giftCardSlice";
import initiatePurchaseSlice from "./slices/initiatePurchaseSlice";
import listAmountSlice from "./slices/listAmountSlice";
import orderDetailsSlice from "./slices/orderDetailsSlice";
import storePurchaseSlice from "./slices/storePurchaseSlice";
import formPersistSlice from "./slices/formPersistSlice";
import paymentStatusSlice from "./slices/paymentStatusSlice";
import authTokenSlice from "./slices/authTokenSlice";
import selectPaymentSlice from "./slices/selectPaymentSlice";

const rootReducer = combineReducers({
  giftCard: giftCardSlice,
  listAmount: listAmountSlice,
  storePurchase: storePurchaseSlice,
  orderDetails: orderDetailsSlice,
  initiatePurchase: initiatePurchaseSlice,
  formData: formPersistSlice,
  paymentStatus: paymentStatusSlice,
  token: authTokenSlice,
  selectPayment: selectPaymentSlice,
});

export default rootReducer;
